<template>
  <div>
    <b-media
      v-if="request.address && request.address.country !== null"
      no-body
      class="mb-1"
    >
      <b-media-aside class="d-none d-lg-block">
        <b-avatar
          rounded
          variant="light-primary"
          size="20"
        >
          <feather-icon
            icon="MapPinIcon"
            size="14"
          />
        </b-avatar>
      </b-media-aside>
      <b-media-body
        class="align-self-center"
      >
        <request-open-map
          :data="request"
        />
        <b-alert
          v-if="request.address_limited"
          show
          variant="dark"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="InfoIcon"
              size="16"
            />
            <span class="ml-25">{{ $t('Full address is displayed only for the service provider who made the service proposal') }}</span>
          </div>
        </b-alert>
      </b-media-body>
    </b-media>
  </div>
</template>

<script>
import {
  BMedia, BMediaAside, BMediaBody, BAvatar, BAlert,
} from 'bootstrap-vue'

import {
  getFormattedAddress,
} from '@/mixins/functions'

import RequestOpenMap from './RequestOpenMap.vue'

export default {
  components: {
    RequestOpenMap,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BAlert,

  },
  mixins: [getFormattedAddress],
  props: {
    request: {
      type: Object,
      default: () => {},
      required: false,
    },
  },

}
</script>
