<template>
  <b-card
    no-body
    class="header"
  >
    <b-row class="text-right actions match-height">
      <b-col v-if="proposalCanBeAdded && ability.cannot('update', point) && ability.cannot('manage', point)">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          block
          @click="handleAddProposalActionClick(point)"
        >
          <feather-icon
            icon="ThumbsUpIcon"
            class="mr-25 d-none d-sm-inline"
          />
          <span>{{ $t('Apply Now') }}</span>
        </b-button>
      </b-col>
      <b-col v-if="'events' in point && point.events.length===0 && point.post_status !=='closed' && ability.can('update', point)">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          @click="handleEditRequestActionClick(point)"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50 d-none d-sm-inline"
          />
          <span class="align-middle">{{ $t('Edit') }}</span>
        </b-button>
      </b-col>
      <b-col v-if="point.post_status !=='closed' && !acceptedProposal && ability.can('update', point)">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          @click="handleCloseRequestActionClick(point)"
        >
          <feather-icon
            icon="BookIcon"
            class="mr-50 d-none d-sm-inline"
          />
          <span class="align-middle">{{ $t('Close') }}</span>
        </b-button>
      </b-col>
      <b-col v-if="point.post_status !=='closed' && acceptedProposal && ability.can('update', point)">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          bloock
          @click="handleCompleteRequestActionClick(point)"
        >
          <feather-icon
            icon="CheckSquareIcon"
            class="mr-50 d-none d-sm-inline"
          />
          <span class="align-middle">{{ $t('Complete') }}</span>
        </b-button>
      </b-col>
      <b-col v-if="proposalAdded && ability.cannot('update', point)">
        <b-button
          variant="success-outline"
          bloock
          disabled
        >
          <feather-icon
            icon="SendIcon"
            class="mr-50 d-none d-sm-inline"
          />
          <span class="align-middle">{{ this.$t('proposal added').toUpperCase() }}</span>
        </b-button>

      </b-col>
      <b-col v-if="point.post_status ==='closed'">
        <b-button
          :variant="`flat-${resolveCloseReasonColorVariant(point.close_reason)}`"
          bloock
          disabled
        >
          <feather-icon
            icon="BookIcon"
            class="mr-50 d-none d-sm-inline"
          />
          <span class="align-middle">{{ this.$t('closed').toUpperCase() }}<small> ( {{ getCloseReasonTitle(point.close_reason) }} )</small></span>
        </b-button>

        <!--          <span-->
        <!--            class="pr-1"-->
        <!--          >-->
        <!--            <b-avatar-->
        <!--              rounded-->
        <!--              :variant="`light-${resolveCloseReasonColorVariant(point.close_reason)}`"-->
        <!--              size="36"-->
        <!--            >-->
        <!--              <feather-icon-->
        <!--                icon="CheckSquareIcon"-->
        <!--                size="24"-->
        <!--              />-->
        <!--            </b-avatar>-->
        <!--            <span-->
        <!--              class="p-1"-->
        <!--              :class="`text-${resolveCloseReasonColorVariant(point.close_reason)}`"-->
        <!--            >{{ this.$t('closed').toUpperCase() }}</span>-->

        <!--            <small>( {{ getCloseReasonTitle(point.close_reason) }})</small>-->
        <!--          </span>-->
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BAvatar,
  BContainer,
  BButtonGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import { usePointRemoteData, usePointsUi } from '@/views/apps/service/usePoints'
import { manageProposalActions, useEventsUI } from '@/views/apps/service/proposal/useProposals'
import { serviceOptions } from '@/mixins/options'
import { showToast } from '@/mixins/notification/toasts'
import { formatDateTime } from '@/filters/dateTime'
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BContainer,
    BButtonGroup,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatDateTime,
  },
  mixins: [showToast],
  props: {
    proposalCanBeAdded: {
      type: Boolean,
      default: false,
    },
    proposalAdded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    point() {
      return store.getters['point/Point']
    },
    acceptedProposal() {
      const proposals = store.getters['proposal/proposals']
      return proposals.find(proposal => this.eventAccepted(proposal))
    },
  },
  methods: {
    deleteRequest(point) {
      store.dispatch('point/deleteServiceRequest', { id: point.id }).then(response => {
        if ([200, 201, 'success'].includes(response.status)) {
          this.showToast(response, 'Request')
          // GlobalEventEmitter.$emit('requestDeleted', point)
          router.push({ name: 'apps-service-my-requests' })
        }
      })
    },

    getAppointmentDate() {
      const { acceptedProposal } = this
      if (acceptedProposal) return acceptedProposal.extendedProps.appointment
      return false
    },
    handleCompleteRequestActionClick() {
      const appointmentDate = this.getAppointmentDate()
      let completeText = ''
      if (appointmentDate && new Date().toISOString() < appointmentDate) {
        completeText += `${this.$t('You have an appointment regarding the request')} </br><b>  ${this.$t('Time')} : ${formatDateTime(appointmentDate)}</b>`
      }
      this.$swal({
        title: this.$t('Are you sure?'),
        html: completeText,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          const payload = {
            id: this.point.id,
            close_reason: Object.keys(serviceOptions.closeReasons)[0],
          }
          this.closeServiceRequest(payload)
            .then(response => {
              this.handleCloseRequestResponse(response, payload)
            })
        }
      })
    },
    handleCloseRequestResponse(response, payload) {
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        GlobalEventEmitter.$emit('request-closed', payload)
        store.commit('point/setPoint', { ...store.getters['point/Point'], ...response.data.data })
        this.showToast(response, null, null, 'Request Closed')
      } else {
        this.showToast(response, 'Request')
      }
    },
    handleCloseRequestActionClick(point) {
      const appointmentDate = this.getAppointmentDate()
      let completeText = ''
      if (appointmentDate && new Date().toISOString() < appointmentDate) {
        completeText += `${this.$t('You have an appointment regarding the request')} </br><b>  ${this.$t('Time')} : ${formatDateTime(appointmentDate)}</b>`
      }
      const errorMessage = this.$t('Please, select a closing reason')
      this.$swal({
        title: this.$t('Are you sure?'),
        icon: 'question',
        input: 'select',
        html: completeText,
        inputOptions: this.getServiceCloseReasons(),
        inputPlaceholder: this.$t('Close Reason'),
        showCancelButton: true,
        confirmButtonText: this.$t('Close'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },

        buttonsStyling: false,
        inputValidator(value) {
          return new Promise(((resolve, reject) => {
            if (value !== '') {
              resolve()
            } else {
              resolve(errorMessage)
            }
          }))
        },
      })
        .then(result => {
          if (result.isConfirmed) {
            const payload = {
              id: point.id,
              close_reason: result.value,
            }
            this.closeServiceRequest(payload).then(response => {
              this.handleCloseRequestResponse(response, payload)
            })
          }
        })
    },
  },
  setup() {
    const ability = defineAbilityForCurrentUser()
    const { eventAccepted } = useEventsUI()
    const { handleAddProposalActionClick } = manageProposalActions()
    const { closeServiceRequest } = usePointRemoteData()
    const { resolveCloseReasonColorVariant, isManaged,handleEditRequestActionClick } = usePointsUi()
    const { getCloseReasonTitle, getServiceCloseReasons } = serviceOptions
    return {
      ability,
      closeServiceRequest,
      resolveCloseReasonColorVariant,
      isManaged,
      getCloseReasonTitle,
      getServiceCloseReasons,
      // poposals
      eventAccepted,
      handleAddProposalActionClick,
      handleEditRequestActionClick
    }
  },
}

</script>
<style scoped>
  .actions .btn {
    height: 100%;
    padding: 1rem;
  }

</style>
