<template>
  <div>

    <b-link
      @click="showModal"
    >
      <span class="align-middle">
        {{ getFormattedAddress(data.address) }}
      </span>
    </b-link>
    <b-modal
      id="map-modal"
      ref="map-modal"
      centered
      hide-footer
      :title="getTitle()"
    >
      <l-map
        ref="map"
        :center="[
          data.lat || defaultLocation.lat,
          data.lng || defaultLocation.lng
        ]"
        :zoom.sync="map.options.zoom"
        :options="map.options"
        :min-zoom="map.options.minZoom"
        :max-zoom="map.options.maxZoom"
        style="height: 320px; width: 100%"
      >
        <l-tile-layer
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
        />
        <l-marker
          v-if="data.lat && data.lng && !data.address_limited"
          visible
          :lat-lng="[
            data.lat || defaultLocation.lat,
            data.lng || defaultLocation.lng
          ]"
        />
        <l-circle
          v-if="data.lat && data.lng && data.address_limited"
          ref="circle"
          :lat-lng="[
            data.lat || defaultLocation.lat,
            data.lng || defaultLocation.lng
          ]"
          :radius="location.radius"
          color="red"
        />
      </l-map>
    </b-modal>

  </div>

</template>

<script>
import {
  BCard, BCardText, BModal, BButton, BLink, VBModal,
} from 'bootstrap-vue'
import { Icon } from 'leaflet'
import {
  LMap, LTileLayer, LMarker, LCircle,
} from 'vue2-leaflet'

import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/dist/geosearch.css'
import Ripple from 'vue-ripple-directive'
import { nextTick } from '@vue/composition-api'
import { getFormattedAddress } from '@/mixins/functions'
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line global-require
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line global-require
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BLink,
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    BModal,

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [getFormattedAddress],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    defaultLocation: {
      type: Object,
      default: () => ({
        lat: 49.444444,
        lng: 32.059722,
      }),
    },
  },
  data() {
    return {
      map: {
        options: {
          zoomControl: true,
          attributionControl: true,
          zoomSnap: true,
          zoom: 14,
        },
      },
      location: {
        radius: 1000,
      },
      tileProvider: {
        url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      },

    }
  },
  methods: {
    getTitle() {
      return this.data.address_limited ? `${this.$t('Within a radius of')+' '+this.location.radius/1000+' '+this.$t('km')}` : this.getFormattedAddress(this.data.address)
    },
    showModal() {
      this.$refs['map-modal'].show()
      nextTick()
      this.rerenderMap()
    },
    rerenderMap() {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },
  },

}
</script>
<style scoped>
.leaflet-container{
  z-index: 0;
}

</style>
