<template>
  <div :class="point.post_status ==='closed'?'locked':''">

    <!-- ECommerce Header -->
    <!--    <section id="ecommerce-header">-->
    <!--      <div class="row">-->
    <!--        <div class="col-sm-12">-->
    <!--          <div class="ecommerce-header-items">-->
    <!--            <div class="result-toggler">-->
    <!--              <feather-icon-->
    <!--                icon="MenuIcon"-->
    <!--                class="d-block d-lg-none"-->
    <!--                size="21"-->
    <!--                @click="mqShallShowLeftSidebar = true"-->
    <!--              />-->
    <!--            </div>-->
    <!--            <div class="search-results">-->
    <!--              {{ totalProposals }} requests found-->
    <!--            </div>-->
    <!--            <div class="view-options d-flex">-->
    <!--              &lt;!&ndash; Per Page &ndash;&gt;-->
    <!--              <b-col>-->
    <!--                &lt;!&ndash;                <label>Show</label>&ndash;&gt;-->
    <!--                <v-select-->
    <!--                  v-model="filters.perPage"-->
    <!--                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
    <!--                  :options="filterOptions.perPage"-->
    <!--                  :clearable="false"-->
    <!--                  class="per-page-selector d-inline-block mx-50"-->
    <!--                />-->
    <!--                &lt;!&ndash;                <label>entries</label>&ndash;&gt;-->
    <!--              </b-col>-->
    <!--              &lt;!&ndash; Sort Button &ndash;&gt;-->
    <!--              <b-dropdown-->
    <!--                v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
    <!--                :text="sortBy.text"-->
    <!--                right-->
    <!--                variant="outline-primary"-->
    <!--              >-->
    <!--                <b-dropdown-item-->
    <!--                  v-for="sortOption in sortByOptions"-->
    <!--                  :key="sortOption.value"-->
    <!--                  @click="sortBy=sortOption"-->
    <!--                >-->
    <!--                  {{ sortOption.text }}-->
    <!--                </b-dropdown-item>-->
    <!--              </b-dropdown>-->

    <!--              &lt;!&ndash; Item View Radio Button Group  &ndash;&gt;-->
    <!--              <b-form-radio-group-->
    <!--                v-model="itemView"-->
    <!--                class="ml-1 list item-view-radio-group"-->
    <!--                buttons-->
    <!--                size="sm"-->
    <!--                button-variant="outline-primary"-->
    <!--              >-->
    <!--                <b-form-radio-->
    <!--                  v-for="option in itemViewOptions"-->
    <!--                  :key="option.value"-->
    <!--                  :value="option.value"-->
    <!--                >-->
    <!--                  <feather-icon-->
    <!--                    :icon="option.icon"-->
    <!--                    size="18"-->
    <!--                  />-->
    <!--                </b-form-radio>-->
    <!--              </b-form-radio-group>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <!--    <div class="ecommerce-searchbar mt-1">-->
    <!--      <b-row>-->
    <!--        <b-col cols="12">-->
    <!--          <b-form-group>-->
    <!--            <b-input-group>-->
    <!--              <b-form-input-->
    <!--                v-model="filters.q"-->
    <!--                placeholder="Search Request"-->
    <!--                class="search-proposal"-->
    <!--              />-->
    <!--              <b-input-group-append is-text>-->
    <!--                <feather-icon-->
    <!--                  icon="SearchIcon"-->
    <!--                />-->
    <!--              </b-input-group-append>-->
    <!--            </b-input-group>-->
    <!--          </b-form-group>-->
    <!--        </b-col>-->
    <!--      </b-row>-->
    <!--    </div>-->

    <section
      :class="itemView"
      class="proposals"
    >
      <b-row class="match-height">
        <b-col
          v-for="proposal in proposals"
          :key="proposal.id"
          cols="12"
          :sm="proposals.length>1?'6':'12'"
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <b-card-text class="pb-1">
              <div class="position-absolute statuses">
                <b-badge
                  v-if="point.receiver && point.receiver.id===proposal.organizer.id"
                  variant="light-warning"
                >
                  <small class="text-nowrap">
                    {{ $t('event.direct_request_proposal') }}
                    <feather-icon
                      icon="BookmarkIcon"
                    />
                  </small>
                </b-badge>

              </div>
            </b-card-text>

            <!-- Product Details -->
            <b-card-body>

              <b-card-text v-if="ability.cannot('update', point)">
                <b-row>
                  <b-col
                    cols="12"
                    xl="6"
                  >
                    <b-media
                      no-body
                    >
                      <b-media-aside>
                        <b-avatar
                          rounded
                          variant="light-primary"
                          size="24"
                        >
                          <feather-icon
                            icon="CalendarIcon"
                            size="14"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body>
                        <h4>
                          {{ proposal.extendedProps.appointment || proposal.extendedProps.start | formatDateTime() }}
                        </h4>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="6"
                  >
                    <b-media
                      no-body
                    >
                      <b-media-aside>
                        <b-avatar
                          rounded
                          variant="light-primary"
                          size="24"
                        >
                          <feather-icon
                            icon="CreditCardIcon"
                            size="14"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body>
                        <h4>
                          <small>{{ $t('from') }} </small>{{ proposal.extendedProps.min_price }} <small> {{ getCurrencySymbol(proposal.extendedProps.currency) }} </small>
                        </h4>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col
                    v-if="proposal.extendedProps.description"
                    cols="12"
                  >
                    <b-media
                      no-body
                    >
                      <b-media-aside>
                        <b-avatar
                          rounded
                          variant="light-primary"
                          size="24"
                        >
                          <feather-icon
                            icon="MessageCircleIcon"
                            size="14"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body>
                        {{ proposal.extendedProps.description }}
                      </b-media-body>
                    </b-media>

                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <hr class="mb-1">
                    <div>{{ $t('Created') }}: {{ proposal.extendedProps.submit_date | formatDateTime() }}</div>
                    <div
                      v-if="proposal.extendedProps.accept_date"
                      class="text-success"
                    >{{ $t('Accepted') }}: {{ proposal.extendedProps.accept_date | formatDateTime() }}</div>
                    <div
                      v-if="proposal.extendedProps.decline_date"
                      class="text-danger"
                    >{{ $t('Declined') }}: {{ proposal.extendedProps.decline_date | formatDateTime() }}<br>
                      {{ $t('Reason') }}: {{ getDeclineReasonTitle(proposal.extendedProps.decline_reason) }}
                    </div>
                    <div
                      v-if="proposal.extendedProps.withdraw_date"
                      class="text-warning"
                    >{{ $t('Withdrawn') }}: {{ proposal.extendedProps.withdraw_date | formatDateTime() }}<br>
                      {{ $t('Reason') }}: {{ getWithdrawReasonTitle(proposal.extendedProps.withdraw_reason) }}
                    </div>
                  </b-col>
                </b-row>
              </b-card-text>
              <b-tabs
                v-if="ability.can('update', point)"
                small
                fill
              >
                <b-tab
                  active
                >
                  <template #title>
                    <feather-icon icon="ThumbsUpIcon" />
                    <span>{{ $t('Proposal') }}</span>
                  </template>
                  <b-card-text>
                    <b-row>
                      <b-col
                        cols="12"
                        xl="6"
                      >
                        <b-media
                          no-body
                        >
                          <b-media-aside>
                            <b-avatar
                              rounded
                              variant="light-primary"
                              size="24"
                            >
                              <feather-icon
                                icon="CalendarIcon"
                                size="14"
                              />
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body>
                            <h4>
                              {{ proposal.extendedProps.appointment || proposal.extendedProps.start | formatDateTime() }}
                            </h4>
                          </b-media-body>
                        </b-media>
                      </b-col>
                      <b-col
                        cols="12"
                        xl="6"
                      >
                        <b-media
                          no-body
                        >
                          <b-media-aside>
                            <b-avatar
                              rounded
                              variant="light-primary"
                              size="24"
                            >
                              <feather-icon
                                icon="CreditCardIcon"
                                size="14"
                              />
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body>
                            <h4>
                              <small>{{ $t('from') }} </small>{{ proposal.extendedProps.min_price }} <small> {{ getCurrencySymbol(proposal.extendedProps.currency) }} </small>
                            </h4>
                          </b-media-body>
                        </b-media>
                      </b-col>
                      <b-col
                        v-if="proposal.extendedProps.description"
                        cols="12"
                      >
                        <b-media
                          no-body
                        >
                          <b-media-aside>
                            <b-avatar
                              rounded
                              variant="light-primary"
                              size="24"
                            >
                              <feather-icon
                                icon="MessageCircleIcon"
                                size="14"
                              />
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body>
                            {{ proposal.extendedProps.description }}
                          </b-media-body>
                        </b-media>

                      </b-col>
                      <b-col
                        cols="12"
                      >
                        <hr class="mb-1">
                        <div>{{ $t('Created') }}: {{ proposal.extendedProps.submit_date | formatDateTime() }}</div>
                        <div
                          v-if="proposal.extendedProps.accept_date"
                          class="text-success"
                        >{{ $t('Accepted') }}: {{ proposal.extendedProps.accept_date | formatDateTime() }}</div>
                        <div
                          v-if="proposal.extendedProps.decline_date"
                          class="text-danger"
                        >{{ $t('Declined') }}: {{ proposal.extendedProps.decline_date | formatDateTime() }}<br>
                          {{ $t('Reason') }}: {{ getDeclineReasonTitle(proposal.extendedProps.decline_reason) }}
                        </div>
                        <div
                          v-if="proposal.extendedProps.withdraw_date"
                          class="text-warning"
                        >{{ $t('Withdrawn') }}: {{ proposal.extendedProps.withdraw_date | formatDateTime() }}<br>
                          {{ $t('Reason') }}: {{ getWithdrawReasonTitle(proposal.extendedProps.withdraw_reason) }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-tab>
                <b-tab>
                  <template #title>
                    <feather-icon icon="UserCheckIcon" />
                    <span>{{ $t('Contractor') }}</span>
                  </template>
                  <b-card-text>
                    <b-card class="text-center">
                      <profile-avatar :user="proposal.organizer" />
                      <h4>{{ proposal.organizer.name }}</h4>
                      <b-card-text>{{ proposal.organizer.bio }}</b-card-text>
                      <!--                  <h6 class="text-muted">-->
                      <!--                    Malaysia-->
                      <!--                  </h6>-->
                      <!--                  <b-badge-->
                      <!--                    class="profile-badge"-->
                      <!--                    variant="light-primary"-->
                      <!--                  >-->
                      <!--                    Pro Level-->
                      <!--                  </b-badge>-->
                      <profile-languages :user="proposal.organizer" />
                      <profile-phones
                        :user="proposal.organizer"
                        :empty-phones-message="$t(`user.phones_are_displayed_if_proposal_has_been_accepted`)"
                        class="p-1"
                      />

                      <!-- follower projects rank -->
                      <!--                  <div class="d-flex justify-content-between align-items-center">-->
                      <!--                    <div>-->
                      <!--                      <h6 class="text-muted font-weight-bolder">-->
                      <!--                        Followers-->
                      <!--                      </h6>-->
                      <!--                      <h3 class="mb-0">-->
                      <!--                        10.3k-->
                      <!--                      </h3>-->
                      <!--                    </div>-->
                      <!--                    <div>-->
                      <!--                      <h6 class="text-muted font-weight-bolder">-->
                      <!--                        Projects-->
                      <!--                      </h6>-->
                      <!--                      <h3 class="mb-0">-->
                      <!--                        156-->
                      <!--                      </h3>-->
                      <!--                    </div>-->
                      <!--                    <div>-->
                      <!--                      <h6 class="text-muted font-weight-bolder">-->
                      <!--                        Rank-->
                      <!--                      </h6>-->
                      <!--                      <h3 class="mb-0">-->
                      <!--                        23-->
                      <!--                      </h3>-->
                      <!--                    </div>-->
                      <!--                  </div>-->
                      <!--/ follower projects rank -->

                    </b-card>
                  </b-card-text>
                  <b-card-text />
                </b-tab>
              </b-tabs>
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">

              <b-button
                v-if="eventCanBeAccepted(proposal)"
                variant="success"
                class="btn-wishlist"
                @click="handleAcceptProposalActionClick(proposal)"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                <span>{{ $t('Accept') }}</span>
              </b-button>
              <b-button
                v-if="eventCanBeDeclined(proposal)"
                variant="danger"
                class="btn-wishlist"
                @click="handleDeclineProposalActionClick(proposal)"
              >
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span>{{ $t('Decline') }}</span>
              </b-button>
              <b-button
                v-if="eventCanBeDeleted(proposal)"
                variant="danger"
                class="btn-wishlist"
                @click="handleDeleteProposalActionClick(proposal)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-50"
                />
                <span>{{ $t('event.delete') }}</span>
              </b-button>
              <b-button
                v-if="eventCanBeWithdrawn(proposal)"
                :disabled="proposal.extendedProps.withdraw_date !== null"
                :variant="proposal.extendedProps.withdraw_date!== null?'dark':'warning'"
                class="btn-wishlist"
                @click="handleWithdrawProposalActionClick(proposal)"
              >
                <feather-icon
                  icon="RotateCcwIcon"
                  class="mr-50"
                />
                <span>{{ $t('Withdraw') }}</span>
              </b-button>
              <b-button
                v-if="eventCanBeUpdated(proposal)"
                variant="primary"
                class="btn-wishlist"
                @click="handleEditProposalActionClick(proposal)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>{{ $t('Edit') }}</span>
              </b-button>

            </div>
          </b-card>
        </b-col>
      </b-row>

    </section>

    <!-- Pagination -->
    <section v-if="totalProposals">
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalProposals"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BButton,
  BPagination,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BTab,
  BTabs,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import Proposal from '@/mixins/classes/proposal'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import store from '@/store'
import { getPostStatusProperty, getUserAvatarText } from '@/mixins/functions'
import { currency, event } from '@/mixins/options'
import { showToast } from '@/mixins/notification/toasts'
import { formatDateTime } from '@/filters/dateTime'
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter'
import ProfileAvatar from '@/views/pages/profile/ProfileAvatar.vue'
import ProfilePhones from '@/views/pages/profile/ProfilePhones.vue'
import ProfileLanguages from '@/views/pages/profile/ProfileLanguages.vue'
import { useShopFiltersSortingAndPagination, useShopUi } from './useProposalList'
import LeftFilterSidebar from './ProposalListLeftFilterSidebar.vue'
import { useEventsUI, useProposalRemoteData, manageProposalActions } from '../useProposals'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV

    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BButton,
    BPagination,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BTab,
    BTabs,
    BBadge,

    ProfileAvatar,
    ProfilePhones,
    ProfileLanguages,

    // SFC
    LeftFilterSidebar,

  },
  filters: {
    formatDateTime,
  },
  mixins: [getPostStatusProperty, getUserAvatarText, showToast, event],
  props: {
    point: {
      type: Object,
      default: () => {},
    },
  },

  created() {
    GlobalEventEmitter.$on('request-closed', () => {
      this.fetchFilteredProposals()
    })
  },
  methods: {
    handleAcceptProposalActionClick(proposal) {
      if (this.acceptedProposal) {
        this.showToast({ status: 'error', statusText: 'Another proposal accepted' }, 'Error')
        return false
      }
      this.$swal({
        title: this.$t('Are you sure?'),
        icon: 'question',
        html: `${this.$t('After accepting this proposal other ones will be automatically declined')}! &#128070;`,
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        footer: !this.eventDateIsActual(proposal) ? `<span class=text-center>${this.$t('message.warning')}! ${this.$t('event.date_and_time_need_to_be_clarified')} &#x1F550</span>` : '',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },

        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.acceptProposal(proposal.id).then(response => {
              const successStatuses = [200, 201]
              if (successStatuses.includes(response.status)) {
                this.showToast(response, null, null, 'Proposal Accepted')
                this.fetchFilteredProposals()
              }
            })
          }
        })
      return true
    },
    handleDeclineProposalActionClick(proposal) {
      if (proposal.extendedProps.accept_date !== null) {
        const errorMessage = this.$t('Please, select a decline reason')
        this.$swal({
          title: this.$t('Are you sure?'),
          icon: 'question',
          html: `${this.$t('Declining an accepted proposal may negatively affect your rating')}! &#128533;`,
          input: 'select',
          inputOptions: this.getDeclineReasons(),
          inputPlaceholder: this.$t('Decline Reason'),
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1',
          },

          buttonsStyling: false,
          inputValidator(value) {
            return new Promise(((resolve, reject) => {
              if (value !== '') {
                resolve()
              } else {
                resolve(errorMessage)
              }
            }))
          },
        })
          .then(result => {
            if (result.isConfirmed) {
              const payload = {
                id: proposal.id,
                decline_reason: result.value,
              }
              this.declineProposal(payload).then(response => {
                const successStatuses = [200, 201]
                if (successStatuses.includes(response.status)) {
                  this.showToast(response, null, null, 'Proposal Declined')
                  this.fetchFilteredProposals()
                } else {
                  this.showToast(response, 'Proposal')
                }
              })
            }
          })
      }
    },
    handleWithdrawProposalActionClick(proposal) {
      const errorMessage = this.$t('Please, select a withdraw reason')
      this.$swal({
        title: this.$t('Are you sure?'),
        icon: 'question',
        html: `${this.$t('Withdrawing an accepted proposal may negatively affect your rating')}! &#128533;`,
        input: 'select',
        inputOptions: this.getWithdrawReasons(),
        inputPlaceholder: this.$t('Withdraw Reason'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },

        buttonsStyling: false,
        inputValidator(value) {
          return new Promise(((resolve, reject) => {
            if (value !== '') {
              resolve()
            } else {
              resolve(errorMessage)
            }
          }))
        },
      })
        .then(result => {
          if (result.isConfirmed) {
            const payload = {
              id: proposal.id,
              withdraw_reason: result.value,
            }
            this.withdrawProposal(payload).then(response => {
              const successStatuses = [200, 201]
              if (successStatuses.includes(response.status)) {
                this.showToast(response, null, null, 'Proposal Withdrawn')
                this.fetchFilteredProposals()
              } else {
                this.showToast(response, 'Proposal')
              }
            })
          }
        })
    },
    handleDeleteProposalActionClick(proposal) {
      this.$swal({
        title: this.$t('Are you sure?'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },

        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            const payload = {
              id: proposal.id,
            }
            this.deleteProposal(payload).then(response => {
              const successStatuses = [200, 201]
              if (successStatuses.includes(response.status)) {
                this.showToast(response, null, null, 'event.proposal_removed')
                this.fetchFilteredProposals()
              } else {
                this.showToast(response, 'Proposal')
              }
            })
          }
        })
    },

  },
  setup(props, { emit }) {
    const {
      getDeclineReasonTitle, getWithdrawReasonTitle, getWithdrawReasons, getDeclineReasons,
    } = event
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const {
      handleCartActionClick, toggleProductInWishlist, handleEditRequestActionClick, userPhones, eventCanBeUpdated,
      eventCanBeWithdrawn, eventCanBeDeclined, eventCanBeAccepted, eventCanBeDeleted, eventAccepted, eventIsActual, eventDateIsActual,
    } = useEventsUI()

    const { handleAddProposalActionClick, handleEditProposalActionClick } = manageProposalActions()

    const {
      itemView, itemViewOptions, totalProposals,
    } = useShopUi()

    const ability = defineAbilityForCurrentUser()

    const {
      proposals, fetchProposals, acceptProposal,
      declineProposal, withdrawProposal, deleteProposal,
    } = useProposalRemoteData()

    const {
      acceptedProposal, getAcceptedProposal, proposalCanBeAdded,
    } = manageProposalActions()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // Wrapper Function for `fetchProposals` which can be triggered initially and upon changes of filters
    const fetchFilteredProposals = () => {
      const args = {

        page: filters.value.page,
        per_page: filters.value.perPage,
      }
      // if (filters.value.proposalPurposes.length !== 0) {
      //   args.purpose_ids = filters.value.proposalPurposes.join()
      // }

      if (filters.value.q) {
        args.q = filters.value.q
      }

      if (sortBy.value.value) {
        args.sort_by = sortBy.value.value
      }

      if (filters.value.userId) {
        args.user_id = filters.value.userId
      }

      if (filters.value.postStatuses.length !== 0) {
        args.post_statuses = filters.value.postStatuses.join()
      }
      args.subject_id = props.point.id
      args.include = ['organizer.phones', 'organizer.languages'].join(',')
      args.order = 'desc'
      fetchProposals(args)
        .then(response => {
          const { total } = response.data.meta
          const successStatuses = [200, 201]
          if (successStatuses.includes(response.status)) {
            proposals.value = store.getters['proposal/proposals'].map($item => new Proposal($item))
            acceptedProposal.value = getAcceptedProposal(proposals.value)
          }
          totalProposals.value = total
          emit('total-proposals', total)
          emit('proposal-can-be-added', proposalCanBeAdded(proposals.value, props.point))
        })
    }

    watch([filters, sortBy], () => {
      fetchFilteredProposals()
    }, {
      deep: true,
      immediate: true,
    })
    const { getCurrencySymbol } = currency()
    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProposals,
      toggleProductInWishlist,
      handleCartActionClick,
      handleEditRequestActionClick,
      handleAddProposalActionClick,
      handleEditProposalActionClick,
      proposalCanBeAdded,
      eventAccepted,
      eventCanBeUpdated,
      eventCanBeWithdrawn,
      eventCanBeDeclined,
      eventCanBeAccepted,
      eventCanBeDeleted,
      eventIsActual,
      eventDateIsActual,
      userPhones,

      // useProposalRemoteData
      proposals,
      fetchFilteredProposals,

      ability,

      // manageProposalActions
      acceptedProposal,
      acceptProposal,
      declineProposal,
      withdrawProposal,
      deleteProposal,
      getDeclineReasonTitle,
      getWithdrawReasonTitle,
      getWithdrawReasons,
      getDeclineReasons,
      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,

      getCurrencySymbol,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.statuses{
  top: -1px!important;
  right:0;
}
.statuses>span{
  border-radius: 0;
  margin-right: 0!important;
}
.statuses>span:first-child:last-child {
  border-radius:  0 0.428rem 0 0.428rem;
}
.statuses>span:first-child {
  border-radius:  0 0 0 0.428rem;
}
.statuses>span:last-child {
  border-radius:  0 0.428rem 0 0 ;
}
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.search-proposal.form-control{
  height: auto;
}

[dir] .ecommerce-application .grid-view .ecommerce-card .item-img {
  padding-top: 0;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  min-height: 15.85rem;
  display: block;
  align-items: center;
}
.ecommerce-application .grid-view .ecommerce-card .item-options {
  flex-wrap: nowrap;
}
.card-img-top {
  max-height: 20em;
  object-fit: cover;
}
.dark-layout .ecommerce-application .ecommerce-card .btn-light {
  background-color: #242B3D !important;
}
.proposals.grid-view{
  margin-top: 0!important;
  grid-template-columns: none;
}

</style>
